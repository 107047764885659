.menu-selected-item {
  border-bottom: 5px var(--color-sunrise-secondary) solid;
}

.menu-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--color-teranet-green-primary);
}

.menu-link::before
.menu-link::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-teranet-green-primary);
  transform: scaleX(1);
  transition: transform .25s;
}

.menu-link::before {
  top: -3px;
  transform-origin: left;
}

.menu-link::after {
  bottom: -3px;
  transform-origin: right;
}

.menu-link:hover::before
.menu-link:hover::after {
  transform: scaleX(1);
}

.user-photo {
  width: 50px;
  height: 68px;
  padding-bottom: 4px;
}

.pull-right {
  float: right !important
}

a {
  color: var(var(--color-teal-secondary));
  text-decoration: none;
}

.anchor {
  text-decoration: underline !important;
  cursor: pointer;
  color: var(--color-dark-primary);
}

.anchor-standard-blue {
  text-decoration: underline !important;
  cursor: pointer;
  color: var(--color-standard-anchor-link);
}

.anchor-white {
  text-decoration: underline !important;
  cursor: pointer;
  color: var(--color-white-primary);
}

.x-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  color: var(--color-black-primary);
}

.mat-mdc-snack-bar-container {
  &.blue-snackbar {
    --mdc-snackbar-container-color: var(--color-teal-secondary);
    --mat-mdc-snack-bar-button-color: var(--color-white-primary);
    --mdc-snackbar-supporting-text-color: var(--color-white-primary);
  }
}

.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: var(--color-slate-gray-primary);
    --mat-mdc-snack-bar-button-color: var(--color-white-primary);
    --mdc-snackbar-supporting-text-color: var(--color-white-primary);
  }
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: var(--color-snackbar-green-primary);
    --mat-mdc-snack-bar-button-color: var(--color-white-primary);
    --mdc-snackbar-supporting-text-color: var(--color-white-primary);
  }
}

.mat-button-toggle-group {
  height: 32px;
  align-items: center;
}

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: normal;
  @media screen and (max-width: $desktopMax) {
    font-size: 18px;
  }
}
