// START - property report styles
.pr-shutter-container {
  @apply lg:flex flex-col sm:flex-row flex-nowrap
}
.pr-shutter-left-sidebar {
  @apply hidden sm:flex-[0_1_15%] sm:block xl:ml-[4rem]
}
.pr-shutter-middle-body-full {
  @apply flex-[1_1_100%] ml-2 mr-2 xl:ml-20 xl:mr-20
}
.pr-shutter-middle-body {
  @apply flex-[0_1_70%] ml-6 mr-6 xl:ml-20 xl:mr-20
}
.pr-shutter-right-sidebar {
  @apply hidden sm:flex-[0_1_15%] sm:block xl:mr-[4rem]
}
.pr-panel-header-title {
  @apply text-[var(--color-teranet-green-primary)]
}
.pr-no-data-gray-background {
  @apply flex flex-row justify-center items-center h-[100px] xl:h-[150px] antialiased text-sm lg:text-base bg-gray-100
}
.pr-vertical-label-data-container {
  @apply flex flex-col w-full gap-2 sm:gap-3
}
.pr-vertical-label-data-container-row {
  @apply flex flex-col w-full gap-2 sm:gap-3 md:flex-row
}
.pr-data-label {
  @apply font-medium text-xs sm:text-sm
}
.pr-data-value {
  @apply text-gray-700 text-sm sm:text-base
}
.pr-summary-data-container {
  @apply flex flex-col gap-2 sm:flex-row sm:divide-x divide-solid
}
.pr-summary-item-container {
  @apply flex h-full flex-col justify-start items-center gap-2 pt-3
}
.pr-summary-address {
  @apply font-medium text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl
}
.pr-summary-owners {
  @apply self-center text-center font-medium text-xs md:text-sm 2xl:text-lg
}
.pr-summary-icon {
  @apply w-[36px] h-[36px] sm:w-[40px] sm:h-[40px] md:w-[50px] md:h-[50px]
}
.pr-summary-icon-label {
  @apply self-center text-center font-medium text-xs 2xl:text-sm
}
.pr-summary-numeric-value {
  @apply self-center text-center font-medium text-base md:text-xl 2xl:text-3xl
}
.pr-summary-numeric-label {
  @apply self-center text-center font-normal text-xs 2xl:text-sm
}
.pr-registry-streetview {
  @apply flex flex-row gap-3
}
.pr-registry-streetview-label {
  @apply flex-1
}
.pr-registry-streetview .pr-registry-streetview-link {
  @apply flex flex-row justify-end gap-1 flex-1
}
.pr-registry-streetview .pr-registry-streetview-link .icon {
  @apply max-h-[22px] max-w-[22px]
}
.pr-registry-static-streetview {
  @apply flex flex-row my-3
}
.pr-registry-static-streetview img {
  @apply flex-grow
}
.pr-site-structure-section-label {
  @apply self-center text-center text-xs md:text-sm 2xl:text-lg flex flex-row gap-2 ;
}
.pr-site-structure-section-label .arn {
  @apply flex flex-row items-center ;
}
.pr-site-structure-section-label .icon {
  @apply flex flex-row items-center font-normal ;
}
.pr-site-structure-section-label .icon mat-icon {
  @apply max-h-[1rem] max-w-[1rem] leading-4 text-base ;
}
.pr-site-structure-arn {
  @apply font-medium;
}
.pr-site-structure-section-left {
  @apply lg:w-1/5 flex flex-row
}
.pr-site-structure-section-right {
  @apply lg:w-4/5;
}
// .pr-site-structure-section [
//     @apply flex-[0_1_70%] ml-6 mr-6 xl:ml-20 xl:mr-20
// ]
// END - property report styles

// START - property report carousel styles
.pr-carousel-product-thumbnail {
  @apply flex-[1_1_30%] flex justify-start items-start
}
.pr-carousel-product-highlight {
  @apply flex-[1_1_70%] flex justify-start items-start
}
// END - property report carousel styles

.hq-ah--data-container {
  @apply pointer-events-none;
}

// Use this class for standard body text in Property Report
.pr-body-text {
  @apply text-xs lg:text-sm 2xl:text-base;
}

.pr-right-menu-img {
  @media screen and (min-width: $tabletMax) and (max-width: 1430px) {
    @apply hidden;
  }
}

@media print {
  .no-print{
    display: none !important;
  }
  .page-break-on-print {
    page-break-before: always;
  }
  h1, .h1 {
    font-size: 1.5rem;
  }

  h4, .h4 {
    font-size: 1.2rem;
  }

  .print-width-80 {
    width: 80% !important;
  }
}
