
button {
  font-size: small;
}

.button {
  background-color: #3a8fef;
  color: white;
  margin-top: 30px;
}

.button-red {
  margin-top: 30px;
  color: #fff;
  background-color: #d2322d;
  border-color: #ac2925;
}

.button-teal {
  margin-top: 30px;
  color: #fff;
  background-color: #39b3d7;
  border-color: #269abc;
}

.button-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.font-bold {
  font-weight: bold;
}

.float-left {
  float: left;
}

.standard-table {
  th {
    background: #efefef !important;
  }

  tr:nth-child(even) {
    background: #efefef;
  }
}

.standard-white-table {
  th {
    background: white !important;
  }
}

tr.mat-mdc-row:hover {
  background-color: #F0F8FF !important;
}

//START - customize the default marker infowindow button
/* hides the default x close image */
button.gm-ui-hover-effect > span {
  display: none !important;
}

/* turn off the scrollbar so that we can position our custom close button above/passed the infowindow container */
.gm-style {
  .gm-style-iw-c {
    overflow: visible;
  }
}

/* customize the close button */
button.gm-ui-hover-effect {
  opacity: 1 !important;
  background: url('../../assets/img/svg/close_x.svg') center center !important;
  background-repeat: no-repeat !important;
  background-size: 12px 12px !important;
  background-color: white !important;
  width: 26px !important;
  height: 26px !important;
  top: -12px !important;
  right: -12px !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2) !important;
}

.dialog-close-btn {
  @apply px-[10px] py-0 mx-[10px] my-0 text-white bg-[var(--color-dark-orange-secondary)] cursor-pointer shadow-md #{!important};
}

.dialog-accept-btn {
  @apply px-[10px] py-0 mx-[10px] my-0 text-white bg-[var(--color-dark-primary)] cursor-pointer shadow-md #{!important};
}

.header-mobile-btn {
  @apply w-44 #{!important};
}

.mat-green {
  background-color: #4c7a15 !important;
  color: #fff !important;
}
.mat-orange {
  background-color: #f78f1e !important;
  color: #fff !important;
}

//END - customize the default marker infowindow button
