// We utilize css variables for the Gema 3G theme. 
// CSS variables have access to the DOM, which means that you can create variables with local or global scope, 
// change the variables with JavaScript, and change the variables based on media queries.

@import 'gema';
@import 'gema-dark';

:root {
  @extend %gema3g-theme-default;
  font-family: 'Open Sans Reg', sans-serif !important;
}

.light-mode {
  background-color: var(--color-white-primary);
  .mat-toolbar {
    background: var(--color-dark-primary);
    color: var(--color-white-primary);
  }
}

.dark-mode {
  @extend %gema3g-theme-dark;
  color: var(--color-white-primary);
  background-color: var(--color-shade-2-custom);

  // ----- material overwrites
  .mat-toolbar {
    background: var(--color-slate-gray-primary);
    color: var(--color-white-primary);
  }
  .mdc-notched-outline > * {
    border-color: var(--color-white-primary) !important;
  }
  .mdc-text-field--focused .mdc-notched-outline > * {
    border-color: var(--color-sunrise-secondary) !important;
  }
  .mdc-text-field--invalid .mdc-notched-outline > * {
    border-color: var(--color-teal-secondary) !important;
  }
  .mat-label {
    color: var(--color-white-primary);
  }
  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    display: none;
  }
  .no-property {
    background: var(--color-shade-2-custom) !important;
  }
  mat-card-content ul li span {
    color: var(--color-white-primary) !important;
  }
  .sort-tabs {
    background: var(--color-slate-gray-primary) !important;
  }
  #estore-h1-retail-title {
    color: var(--color-slate-gray-primary) !important;
  }
}