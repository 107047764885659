.sr-property-container{
  @apply flex flex-row;
}
.sr-property-container:hover {
  background: #e3f2fe;
}
.sr-item-image-container{
  @apply  basis-1/6 px-4 py-3;
}
.sr-property-description-container{
  @apply basis-5/6 py-3;
}
.sr-property-description-first-item{
  @apply flex flex-row min-w-[26px];
}
.sr-item-image{
  @apply p-2 w-10;
}
.sr-details-label{
  @apply min-w-[40px];
}
.sr-details-text{
  @apply pl-2;
}
.sr-sorting-container{
  @apply bg-white z-20;
}
.sr-results-found {
  @apply h-[40px] border-b-2 py-3 bg-[#ebebeb] pl-[76px];
  strong {
    @apply relative text-left text-[#333333];
  }
}
.sr-container {
  @apply flex flex-col xs:top-32 sm:top-20 md:top-4;
  @media screen and (min-height: $mobileMaxHeight) and (min-width: $mobileMax) {
    @apply absolute left-5 w-[470px] xs:h-[calc(100%-320px)] sm:h-[calc(100%-400px)] md:h-[calc(100%-220px)];
  }
  .header{
    @apply flex bg-[#6f6f6f] text-center text-white text-4xl rounded-t-lg z-10 py-2 ;
  }
  .header-left{
    @apply w-1/12;
  }
  .header-middle{
    @apply flex-grow text-xl w-8/12;
  }
  .header-right{
    @apply w-1/12;
  }
  .chevron-up-down{
    @apply top-[0.75rem] left-[25rem];
  }
  .chevron-left{
    @apply top-[0.75rem] left-[1rem];
  }
  .no-results{
    @apply text-center bg-white z-10;
  }
  .searching{
    @apply bg-white text-center z-10;
  }
  .no-more-results {
    @apply p-1.5 font-semibold ;
  }
  .search-results {
    @apply bg-white;
  }
  .mobile-visible {
    @media screen and (min-height: $mobileMaxHeight) and (min-width: $mobileMax) {
      @apply hidden;
    }
  }
  .mobile-hidden {
    @apply hidden;
    @media screen and (min-height: $mobileMaxHeight) and (min-width: $mobileMax) {
      @apply block;
    }
  }
}
