%gema3g-theme-default {
  // Theme palette Hex values pulled from the brand guildelines

  $account-background-color: #e5ebf6;
  --color-teranet-green-primary: #8DC63F;
  --color-dark-primary: #4C7A15; // teranet
  --color-snackbar-green-primary: #7eb238;
  --color-slate-gray-primary: #55565B;
  --color-slate-gray-icon-primary: #9c9ea3;
  --color-black-primary: #000000;
  --color-white-primary: #FFFFFF;
  --color-blue-primary: #3a8fef;
  --color-grey-primary: #f5f5f5;
  --color-grey-secondary: #efefef;
  --color-background-mat-card: #FFFFFF;
  --color-text-mat-card: #000000;

  --color-smalt-secondary: #004D94;
  --color-sunrise-secondary: #F78F1E;
  --color-dark-orange-secondary: #D04A06;
  --color-maroon-secondary: #800000;
  --color-violet-secondary: #390142;
  --color-cherry-secondary: #8D176B;
  --color-teal-secondary: #3E6379;

  --color-shade-1-custom: #252525;
  --color-standard-anchor-link: #0000EE;
  --color-standard-anchor-visited-link: #551A8B;

  --color-store-ad-protection-box: #14a59d;
  --color-store-ad-hoodq-box: #F0533F;

  --3g-font-family: 'Open Sans Reg', sans-serif !important;

  /* Label */
  --3g-label-font-weight: 400;
  --3g-label-font-size: 16px;
  --3g-label-checkbox-font-weight: 400;

  /* Headings */
  --3g-h1-font-size: 40px;
  --3g-h2-font-size: 32px;
  --3g-h3-font-size: 28px;
  --3g-h4-font-size: 24px;
  --3g-h5-font-size: 20px;
  --3g-h6-font-size: 16px;
  --3g-h1-font-weight: 400;
  --3g-h2-font-weight: 400;
  --3g-h3-font-weight: 400;
  --3g-h4-font-weight: 400;
  --3g-h5-font-weight: 400;
  --3g-h6-font-weight: 400;

  /* Text */
  --3g-text-secondary-color: var(--color-black-primary);
  --3g-highlight-lg-size: 40px;
  --3g-highlight-md-size: 20px;
  --3g-highlight-sm-size: 16px;
  --3g-highlight-color: var(--teal);
  --color-text-light-gray: #ACACAC;

  /* Section Heading */
  --3g-section-heading-font-weight: 400;
  --3g-section-heading-color: var(--color-teranet-green-primary);
  --3g-section-heading-font-size: 16;
  --3g-section-heading-decoration: underline;

  /* Input */
  --3g-input-font-color: var(--color-black-primary);
  --3g-input-group-border-radius: 0;

  // Placeholders
  // --color-success: #507000;
  // --color-warning: #B94700;
  // --color-critical: #C51114;
  // --color-disabled: #e9ecef;
  // --color-shadow-primary: rgba(0, 0, 0, 0.12);
}
