.account-main-white {
  background: white;
  height: 100%;
}

.svg-icon {
  width: 14px;
  height: 14px;
  display: inline;
}

.login-warning-div {
  @apply py-3 mb-4 w-full min-[400px]:max-w-sm;
  border: 2px solid;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 7px;
  background-color: var(--color-dark-orange-secondary);
  color: white;

  p {
    color: cornflowerblue;
  }
}

.success-message {
  border-radius: 3px;
  width: 100%;
  height: 45px;
  color: #00970e;
  line-height: 45px;
  background-color: rgba(0, 150, 14, 0.02);
  border-radius: 3px;
  border-width: 1px;
  border-color: #00960e;
  border-style: solid;
  margin-top: 8px !important;

  .img-checkmark {
    margin-right: 10px;
    margin-left: 10px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    display: inline-block;
  }
}

.spinner{
  display: flex;
  align-items: center;
  justify-content: center;
}

.geo-container{
  @apply px-[15px] mx-auto w-[768px] lg:w-[970px] xl:w-[1170px] border-solid
}
.geo-mat-menu-horizontal {
  display: flex ! important;
  flex-direction: row ! important;
  justify-items: center;
  align-content: center ! important;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.grey-background{
  background: #efefef;
}
.gray_background{
  background: #f5f5f5 !important;
}

.mat-expansion-panel-body {
  @media screen and (max-width: $mobileMax) {
    padding: 0 0 4px 0 !important;
  }
}

.legal-img {
  width: 13px;
  height: 13px;
}

.info-icon{
  @apply  align-bottom mb-1 w-[14px] h-[14px]
}

.pinboxstyle {
  width: 100%;
  height: 26px;
}
.pinboxstyle .pinstyle {
  text-align: center;
  background-color: #3a8fef;
  color: #ffffff;
  padding: 7px;
  font-size: 11px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}
.pinboxstyle .pinnumberstyle {
  text-align: center;
  background-color: #ffffff;
  color: #1c1c1c;
  font-size: 11px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  padding: 7px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}
.arnboxstyle {
  width: 100%;
  height: 26px;
}
.arnboxstyle .arnstyle {
  text-align: center;
  background-color: #ff9c00;
  color: #ffffff;
  font-size: 11px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  padding: 7px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}
.arnboxstyle .arnnumberstyle {
  text-align: center;
  background-color: #ffffff;
  color: #1c1c1c;
  font-size: 11px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  padding: 7px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.search-comps-polygon-in-progress-instruction-box {
  width: 100%;
  height: 26px;
}
.search-comps-polygon-in-progress-instruction-box .left {
  min-width: 0.2rem;
  max-width: 0.2rem;
  text-align: center;
  background-color: #ff9c00;
  color: #ffffff;
  font-size: 11px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  padding: 7px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}
.search-comps-polygon-in-progress-instruction-box .right {
  text-align: center;
  background-color: #ffffff;
  color: #1c1c1c;
  font-size: 11px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  padding: 7px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.mat-table-highlighted-row{
  @apply bg-gray-100 border-l-4 border-solid border-[#2196f3] #{!important};
}
