.map-top-right-controls {
  @apply relative flex flex-row mt-2 mr-2;
  .zoom-in {
    @apply w-[26px] h-[26px] ml-1 rounded-l-lg border-r-2 bg-white;
    img {
      @apply w-[26px] h-[26px];
    }
  }

  .zoom-in:hover {
    @apply bg-gray-300;
  }

  .zoom-out {
    @apply w-[26px] h-[26px] rounded-r-lg ml-0.5 bg-white border-r-2 border-gray-400;
    img {
      @apply w-[26px] h-[26px];
    }
  }

  .zoom-out:hover {
    @apply bg-gray-300;
  }

  .zoom-in-black {
    @apply w-[26px] h-[26px] ml-1 rounded-l-lg border-r-2 bg-[#565656];
    img {
      @apply w-[26px] h-[26px];
    }
  }

  .zoom-in-black:hover {
    @apply bg-gray-300;
  }

  .zoom-out-black {
    @apply w-[26px] h-[26px] rounded-r-lg ml-0.5 bg-[#565656] border-r-2 border-gray-400;
    img {
      @apply w-[26px] h-[26px];
    }
  }

  .zoom-out-black:hover {
    @apply bg-gray-300;
  }

  .enter-full-map {
    @apply bg-[url('/assets/img/svg/icons/icon_map_enter_full_map.svg')] w-[26px] h-[26px] ml-1 rounded-lg bg-white;
  }

  .enter-full-map:hover {
    @apply bg-gray-300;
  }

  .exit-full-map {
    @apply bg-[url('/assets/img/svg/icons/icon_map_exit_full_map.svg')] w-[26px] h-[26px] ml-1 rounded-lg bg-white hidden;
  }

  .exit-full-map:hover {
    @apply bg-gray-300 hidden;
  }

  .enter-full-map-black {
    @apply bg-[url('/assets/img/svg/icons/icon_map_enter_full_map_white.svg')] w-[26px] h-[26px] ml-1 rounded-lg bg-[#565656];
  }

  .enter-full-map-black:hover {
    @apply bg-gray-300;
  }

  .exit-full-map-black {
    @apply bg-[url('/assets/img/svg/icons/icon_map_exit_full_map_white.svg')] w-[26px] h-[26px] ml-1 rounded-lg bg-[#565656] hidden;
  }

  .exit-full-map-black:hover {
    @apply bg-gray-300 hidden;
  }

  .copy-map {
    @apply bg-[url('/assets/img/svg/icons/icon_map_copy.svg')] w-[26px] h-[26px] ml-1 rounded-lg bg-white;
  }

  .copy-map:hover {
    @apply bg-gray-300;
  }

  .copy-map-black {
    @apply bg-[url('/assets/img/svg/icons/icon_map_copy_white.svg')] w-[26px] h-[26px] ml-1 rounded-lg bg-[#565656];
  }

  .copy-map-black:hover {
    @apply bg-gray-300;
  }

}

.map-measure-controls {
  @apply relative flex flex-row;
  .measure-control {
    @apply flex flex-row max-w-fit bg-white cursor-pointer rounded-lg m-2;
    .measure-img {
      @apply bg-[url('/assets/img/svg/icons/ruler.svg')] w-[26px] h-[26px] ml-0.5;
    }

    .measure-button {
      @apply h-[26px] w-[75px] p-2;
      .measure-text {
        @apply w-[44px] h-[8px];
      }
    }
  }

  .measure-control:hover {
    @apply bg-[#cccccc] text-black;
  }

  .erase-measure-control {
    @apply flex flex-row max-w-fit bg-white cursor-pointer rounded-lg my-2 hidden;
    .erase-measure-img {
      @apply bg-[url('/assets/img/svg/icons/clear_measurement.svg')] w-[26px] h-[26px] ml-0.5;
    }

    .erase-measure-button {
      @apply h-[26px] w-[55px] p-2;
    }
  }

  .erase-measure-control:hover {
    @apply bg-[#cccccc] text-black;
  }
}

.map-measure-distance-control {
  @apply h-[26px] max-w-[300px] cursor-pointer rounded-lg bg-white text-center m-2 p-2 hidden;
  .distance-control-text {
    @apply px-2 text-center;
  }
}

.map-measure-view-menu-button {
  @apply w-[26px] h-[26px] pt-2 bg-white rounded-lg mt-2 mr-0.5;
  .menu-img {
    @apply w-[26px] mt-[-7px];
  }
}

.map-measure-view-menu-options-large {
  @apply w-[206px] bg-white rounded-lg  mr-2 mt-0.5 invisible cursor-pointer;

  .map-view-option {
    @apply flex flex-row bg-white p-2;
    .icon {
      @apply flex flex-col justify-center items-center pr-2 w-[40px] h-[40px];
    }
    .option-container {
      @apply flex flex-col justify-start w-full ;
      .label {
        @apply flex flex-col justify-center text-[13px] max-h-[24px] ;
      }
      .label-small-hidden {
        @apply flex flex-col justify-center text-[13px] max-h-[24px];
      }
      .bold {
        @apply font-semibold ;
      }
      .tilt {
        @apply flex flex-row justify-start gap-3 pt-0.5;
      }
    }
  }
  .map-view-option:hover {
    @apply bg-[#cccccc] text-black;
    .option-container {
      .label {
        @apply font-semibold;
      }
    }
  }
  .map-view-option:nth-child(2) {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }
  .map-view-option:nth-child(1) {
    @apply rounded-t-lg;
  }
  .map-view-option:nth-child(3) {
    @apply rounded-b-lg;
  }
}
.map-measure-view-menu-options-small {
  @apply w-[126px] bg-white rounded-lg  mr-2 mt-0.5 invisible cursor-pointer;

  .map-view-option {
    @apply flex flex-row bg-white p-2;
    .icon {
      @apply flex flex-col justify-center items-center pr-2 w-[40px] h-[40px] hidden;
    }
    .option-container {
      @apply flex flex-col justify-start w-full ;
      .label {
        @apply flex flex-col justify-center text-[13px] max-h-[24px] ;
      }
      .label-small-hidden {
        @apply flex flex-col justify-center text-[13px] max-h-[24px] hidden;
      }
      .bold {
        @apply font-semibold ;
      }
      .tilt {
        @apply flex flex-row justify-start gap-3  pt-0.5 hidden;
      }
    }
  }
  .map-view-option:hover {
    @apply bg-[#cccccc] text-black;
    .option-container{
      .label{
        @apply font-semibold;
      }
    }
  }
  .map-view-option:nth-child(2) {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }
  .map-view-option:nth-child(1) {
    @apply rounded-t-lg;
  }
  .map-view-option:nth-child(3) {
    @apply rounded-b-lg;
  }
}
