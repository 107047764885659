@font-face {
  font-family: 'Open Sans Reg';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../src/fonts/opensans/OpenSans-Regular.ttf) format('woff2');
}

@font-face {
  font-family: 'Open Sans Bold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../src/fonts/opensans/OpenSans-Bold.ttf) format('woff2');
}