.text-interface {
  font-family: var(--3g-font-family);
  font-size: var(--3g-font-size);
  font-style: normal;
  // line-height: var(--3g-font-line-height);
  letter-spacing: var(--3g-font-letter-spacing);
}
 
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .text-interface;
}
 
:root {
  h1, .h1 {
    --3g-font-size: var(--3g-h1-font-size);
    --3g-font-line-height: 54px;
    font-weight: var(--3g-h1-font-weight);
    margin: 0;
    text-align: left;
  }
 
  h2, .h2 {
    --3g-font-size: var(--3g-h2-font-size);
    --3g-font-line-height: 44px;
    font-weight: var(--3g-h2-font-weight);
  }
 
  h3, .h3 {
    --3g-font-size: var(--3g-h3-font-size);
    --3g-font-line-height: 38px;
    font-weight: var(--3g-h3-font-weight);
  }
 
  h4, .h4 {
    --3g-font-size: var(--3g-h4-font-size);
    --3g-font-line-height: 33px;
    font-weight: var(--3g-h4-font-weight);
  }
 
  h5, .h5 {
    --3g-font-size: var(--3g-h5-font-size);
    --3g-font-line-height: 27px;
    font-weight: var(--3g-h5-font-weight);
  }
 
  h6, .h6 {
    --3g-font-size: var(--3g-h6-font-size);
    --3g-font-line-height: 22px;
    font-weight: var(--3g-h6-font-weight);
  }
 
  .section-heading {
    font-weight: var(--3g-section-heading-font-weight);
    font-size: var(--3g-section-heading-font-size);
    color: var(--3g-section-heading-color);
    text-decoration: var(--3g-section-heading-decoration)
  }
 
  .help-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: var(--3g-muted-dark-color);
  }
 
  .text-primary {
    color: var(--3g-primary) !important;
  }
 
  .text-secondary {
    color: var(--3g-text-secondary-color) !important; // default bootstrap defines it as important
    font-size: var(--3g-text-secondary-font-size);
  }
 
  .text-highlight-lg {
    color: var(--3g-highlight-color);
    font-size: var(--3g-highlight-lg-size);
  }
 
  .text-highlight-md {
    color: var(--3g-highlight-color);
    font-size: var(--3g-highlight-md-size);
  }
 
  .text-highlight-sm {
    color: var(--3g-highlight-color);
    font-size: var(--3g-highlight-sm-size);
  }

  // Use this class anywhere that requires Open Sans Bold text that is not contained in an h1, h2, h3, or h4 element
  .text-os-bold {
    font-family: 'Open Sans Bold', sans-serif !important;
  }
}






@media screen and (min-width: $screen-sm-min) {
  h1 {
    font-size: $sm-h1-font-size;
  }
}

@media screen and (min-width: $screen-md-min) {
  h1 {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: $desktopMax) {
  h1 {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: $screen-xl-min) {
  h1 {
    font-size: 1.1rem;
  }
}