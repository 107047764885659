.cp-green {
  color: var(--color-dark-primary);
}
.cp-special-offer-ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.cp-special-offer-ribbon span {
  font-size: 9px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 17px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #F78F1E;
  background: linear-gradient(#F78F1E 0%, #F78F1E 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 18px; right: -27px;
}
.cp-special-offer-ribbon span::before {
  content: "";
  position: absolute; left: 3px; top: 100%;
  z-index: -1;
  border-left: 3px solid #F78F1E;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #F78F1E;
}
.cp-special-offer-ribbon span::after {
  content: "";
  position: absolute; right: 8px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #F78F1E;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #F78F1E;
}
.cp-middle-ribbon {
  --f: 0px;
  --r: 7px;
  position: relative;
  top: 10px;
  left: -10px;
  width: 80px;
  padding-left: 5px;
  background: gray;
  color: #ffffff;
  border-bottom: var(--f) solid #0005;
  border-right: var(--r) solid #0000;
  clip-path:
    polygon(100% 0,0 0,0 calc(100% - var(--f)),var(--f) 100%,
      var(--f) calc(100% - var(--f)),100% calc(100% - var(--f)),
      calc(100% - var(--r)) calc(50% - var(--f)/2));
}
.cp-product-image{
  width: 80px !important;
  height: 125px !important;
  padding-bottom: 10px;
  padding-top: 5px;
}

.cp-product-image-subscription {
  width: 83px !important;
  height: 21px !important;
  margin-top: 19px;
  @media screen and (width: $tabletMax) {
    width: 72px !important;
    height: 21px !important;
    margin-top: 19px;
  }
}
.cp-bullet{
  font-size: clamp(0.5625rem, 0.4678rem + 1.5152vw, 0.625rem);
}
.cp-start-html{
  font-size: clamp(0.5625rem, 0.4678rem + 1.5152vw, 0.625rem);
}
.cp-end-html{
  font-size: clamp(0.5625rem, 0.4678rem + 1.5152vw, 0.625rem);
}
