%gema3g-theme-dark{
  // Theme palette Hex values pulled from the brand guildelines

  $account-background-color: #e5ebf6;

  --color-teranet-green-primary: #8DC63F;
  --color-dark-primary: #4C7A15; // teranet
  --color-snackbar-green-primary: #7eb238;
  --color-slate-gray-primary: #55565B;
  --color-black-primary: #000000;
  --color-white-primary: #FFFFFF;
  --color-blue-primary: #3a8fef;
  --color-background-mat-card: #55565B;
  --color-text-mat-card: #FFFFFF;

  --color-smalt-secondary: #004D94;
  --color-sunrise-secondary: #F78F1E;
  --color-dark-orange-secondary: #D04A06;
  --color-maroon-secondary: #800000;
  --color-violet-secondary: #390142;
  --color-cherry-secondary: #8D176B;
  --color-teal-secondary: #3E6379;


  --color-shade-1-custom: #212121;
  --color-shade-2-custom: #252525;

  --color-text-light-gray: #ACACAC91;
}
