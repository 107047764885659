// This is our main entry file. Every scss class/variable or mixin is imported here. 
// ==========================================================================
// Third Party
// ==========================================================================
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "node_modules/primeng/resources/primeng.min.css";
@import "node_modules/primeicons/primeicons.css";

.get-dense-3 {
  @include mat.all-component-densities(-3);
}

.get-dense-3 .mat-mdc-raised-button.mat-mdc-button-base {
  height: 36px;
  font-size: 0.875rem !important;
}

fa-icon {
  color: var(--color-slate-gray-icon-primary)
}

// ==========================================================================
// Utilities - SCSS variables, placeholders, mixins, functions
// ==========================================================================
@import 'utilities';

// ==========================================================================
// Theme - CSS variables
// ==========================================================================
@import "theme";

// ==========================================================================
// Global Classes - Styles applied everywhere
// ==========================================================================
@import 'global-classes';

// ==========================================================================
// Fonts - Font styles applied everywhere
// ==========================================================================
@import 'fonts';

// ==========================================================================
// Resets
// ==========================================================================
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

*,
::before,
::after {
  border-style: none;
}

img, svg {
  display: inline;
}

.google-map * {
  border-style: none;
}
