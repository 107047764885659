.gema3g-ss-container {
  &__inner {
    @apply sm:px-0;
    &-grid {
      @apply grid grid-cols-1 md:grid-cols-12 md:my-2 text-sm 2xl:text-lg;
      .l-column { @apply col-span-2 pb-2 md:pt-2 md:pr-4 md:pb-2 md:pl-0; h4 { @apply mt-7 md:mt-0; margin-bottom: 7px !important} }
      .m-column { @apply col-span-5 md:pb-2 md:p-2 md:mr-5; div { border-bottom: 1px solid #ccc; padding: 7px 0 7px 0 } }
      .r-column { @apply col-span-5 md:pb-2 md:pt-2 md:pr-0 md:ml-5 md:pb-2 md:pl-4; div { border-bottom: 1px solid #ccc; padding: 7px 0 7px 0 } }
    }
    &-grid-2 {
      @apply grid grid-cols-1 md:grid-cols-12 my-2 text-sm 2xl:text-lg;
      .l-column { @apply col-span-2 pb-2 md:pt-4 md:pr-4 md:pb-4 md:pl-0}
      .r-column { @apply col-span-5 pb-8 md:pt-4 md:pr-0 md:pb-4 md:pl-4 }
    }
    &-grid-3 {
      @apply grid grid-cols-1 md:grid-cols-12 md:my-8 px-6 2xl:px-0 max-w-[1200px] text-sm lg:text-sm xl:text-sm 2xl:text-lg;
      div.item-wrapper { @apply mt-9 lg:mt-0 }
      .l-column { @apply col-span-4 md:mr-5 }
      .m-column { @apply col-span-4 md:mr-5; }
      .r-column { @apply col-span-4 md:pr-0 md:ml-5 md:pl-4; }
    }
    &-grid-2-column {
      @apply grid grid-cols-1 md:grid-cols-12 md:mt-2 md:mb-8 px-6 2xl:px-0 max-w-[1200px] text-sm 2xl:text-base;
      div.item-wrapper { @apply mt-9 lg:mt-0 }
      .l-column { @apply col-span-6 md:mr-5;}
      .r-column {
        @apply col-span-6 md:pr-0;
        &__child-grid {
          @apply grid grid-cols-1 md:grid-cols-12 mt-2 mb-8;
          .l-column {
            @apply p-4 mb-4 md:mr-0 rounded-md;
            border: 1px solid #ccc;
            &.active { background-color: var(--color-dark-primary); color: var(--color-white-primary);}
            &:nth-child(odd) {
              @apply md:mr-4;
            }
          }
        }
      }
    }
    &-grid-multi {
      @apply grid grid-cols-1 sm:grid-cols-12 md:my-8 max-w-[1200px] text-sm lg:text-sm xl:text-sm;
      div.item-wrapper { @apply mt-9 lg:mt-0 }
      .l-column {
        @apply sm:col-span-6 md:col-span-4 lg:col-span-3 mb-6 sm:mr-6;
        border: 1px solid #ccc;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        .mat-mdc-card {
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0) !important;
        }
      }
      .l-column-for-card {
        @apply sm:col-span-6 md:col-span-4 lg:col-span-3 mb-6 sm:mr-6;
        border: 1px solid #ccc;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        position: relative;
        .mat-mdc-card {
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}
.carousel-container {
  .p-carousel-items-container {
    @apply pb-1;
    .carousel-card {
      @apply h-[100%];
      &__block {
        @apply h-[100%] flex flex-col shadow-md rounded-lg mr-2 border-solid border-slate-500 border relative max-w-[300px];
      }
    }
  }
}

@media (max-width: 1440px) {
  #plans-survey.carousel-container {
    @apply min-w-[135%];
  }
}


mat-card-content ul {
  @apply list-disc mt-5 mb-3 ml-5 text-xs;
  li {
    @apply pb-1;
    color: var(--color-teranet-green-primary);
    span {
      color: var(--color-black-primary)
    }
  }
}

mat-card-content .ul-after-text {
  @apply list-disc my-0.5 ml-5 text-xs;
  li {
    @apply pb-1;
    color: var(--color-teranet-green-primary);
    span {
      color: var(--color-black-primary)
    }
  }
}

.flex-full-width {
  @include flexFullWidth;
}

.flex-center {
  @include flexCenter;
}


.flex-container {
  display: flex;
}

.geo-flex-row {
  @apply flex flex-row flex-wrap w-full;
}

.geo-flex-row-nowrap {
  @apply flex flex-row flex-nowrap w-[98%];
}

.geo-flex-column {
  @apply flex flex-col ml-4 mt-4;
}

.geo-double-column {
  @apply flex-[2_0_100%] flex-col ;
}

.geo-half-column {
  @apply flex-[1_0_50%] p-[10px] ml-2 mt-2 box-border;
}

.flex-25-percent-column {
  @apply flex-[1_1_25%];
}
.flex-75-percent-column {
  @apply flex-[1_1_75%];
}




.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.justify-content-center {
  justify-content: center;
}

.margin-bottom-10 {
  margin-bottom: 0.625rem;
}

.margin-bottom-1 {
  margin-bottom: 0.0625rem;
}

.margin-top-10 {
  margin-top: 0.625rem;
}

.margin-top-0 {
  margin-top: 0px ! important;
}

.margin-top-1 {
  margin-top: 0.0625rem;
}

.padding-top-1 {
  padding-top: 0.0625rem;
}

.padding-top-10 {
  padding-top: 0.625rem;
}

.padding-top-20 {
  padding-top: 1.25rem;
}

.padding-top-30 {
  padding-top: 1.875rem;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.mobile-full-screen {
  gema3g-ticker-tape, gema3g-header, gema3g-omnibar-search, .map-horizontal-loading-indicator, gema3g-hoodq-map, gema3g-footer {
    @apply hidden;
  }
}
