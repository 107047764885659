@mixin flexFullWidth() {
  @apply flex flex-row w-full
}

@mixin flexCenter() {
  @apply flex-1 flex flex-col justify-center items-center
}

@mixin matButtonBaseOrange() {
  background-color: var(--color-sunrise-secondary);
  color: var(--color-white-primary);
  @apply text-xs sm:text-sm;
}

@mixin matButtonBaseGreen() {
  background-color: var(--color-dark-primary);
  color: var(--color-white-primary);
  @apply text-xs sm:text-sm;
}

@mixin matButtonBaseBlue() {
  background-color: var(--color-smalt-secondary);
  color: var(--color-white-primary);
  @apply text-xs sm:text-sm;
}


// // small devices
// @mixin sm {
//     @media (min-width: #{$screen-sm-min}) {
//         @content;
//     }
//  }

//  // medium devices
//  @mixin md {
//     @media (min-width: #{$screen-md-min}) {
//         @content;
//     }
//  }

//  // large devices
//  @mixin lg {
//     @media (min-width: #{$screen-lg-min}) {
//         @content;
//     }
//  }

//  // extra large devices
//  @mixin xl {
//     @media (min-width: #{$screen-xl-min}) {
//         @content;
//     }
//  }