.store-front-main-container {
  @apply flex flex-col items-center gap-4;
}

//START - borrowed styles from gema-ui for push pin marker infowindow
.bubble-container {
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-print-color-adjust: exact !important;
  background-color: #ffffff !important;
  width: 364px;
}

.bubble-container .bubble-header {
  width: 100%;
  height: 55px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #3a84ef !important;
}

.bubble-container .bubble-header .bubble-header-left {
  width: 14%;
  height: 100%;
  float: left;
  text-align: center;
  padding-top: 4%;
}

.bubble-container .bubble-header .bubble-header-left .bubble-header-left-img {
  width: 24px;
  height: 24px;
}

.bubble-container .bubble-header .bubble-header-center {
  width: 72%;
  height: 100%;
  float: left;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  display: table;
}

.bubble-container .bubble-header .bubble-header-center .bubble-header-center-dtls {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.15em;
}

.bubble-container .bubble-header .bubble-header-right {
  width: 14%;
  text-align: center;
  float: left;
  padding-top: 6%;
}

.bubble-container .bubble-header .bubble-header-right .bubble-header-right-img {
  width: 13px;
  height: 13px;
  opacity: 0.4;
}

.bubble-container .bubble-header .bubble-header-right .bubble-header-right-img:hover {
  cursor: pointer;
  opacity: 1.0;
}

.bubble-container .bubble-body {
  width: 100%;
}

.bubble-container .bubble-body .bubble-desc {
  padding-left: 14%;
  padding-right: 14%;
  padding-top: 20px;
  font-size: 13px;
  color: #1c1c1c;
}

.bubble-container .bubble-body .bubble-pin {
  float: left;
  padding-left: 14%;
  padding-top: 13px;
}

.bubble-container .bubble-body .bubble-pin .bubble-pin-header {
  font-size: 13px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
}

.bubble-container .bubble-body .bubble-pin .bubble-pin-details {
  font-size: 13px;
  font-family: 'open_sansregular', Arial, Helvetica, "sans serif";
}

.bubble-container .bubble-body .bubble-arn {
  padding-right: 14%;
  padding-top: 13px;
  float: right;
}

.bubble-container .bubble-body .bubble-arn .bubble-arn-header {
  font-size: 13px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
}

.bubble-container .bubble-body .bubble-arn .bubble-arn-details {
  font-size: 13px;
  font-family: 'open_sansregular', Arial, Helvetica, "sans serif";
}

.bubble-container .bubble-body .bubble-links {
  padding-left: 14%;
  padding-right: 14%;
  padding-top: 15px;
  color: #d6d6d6;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left {
  width: 50%;
  float: left;
  padding-bottom: 15px;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left .bubble-links-left-img {
  width: 20px;
  height: 20px;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left .bubble-links-left-dtls {
  padding-left: 2px;
  font-size: 13px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  color: #3a84ef;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left .bubble-links-left-dtls-disabled {
  padding-left: 4px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  font-size: 13px;
  color: #5E5E5E;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left .bubble-links-left-dtls-disabled .tool-tip {
  visibility: hidden;
  width: 100px;
  background-color: white;
  color: #5E5E5E;
  text-align: center;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #5E5E5E;
  padding: 5px 0;
  top: 100%;
  left: 30px;
  position: absolute;
  font-size: 9px;
  z-index: 1;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left .bubble-links-left-dtls-disabled .tool-tip::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #5E5E5E transparent;
}

.bubble-container .bubble-body .bubble-links .bubble-links-left .bubble-links-left-dtls-disabled:hover .tool-tip {
  visibility: visible;
}

.bubble-container .bubble-body .bubble-links .bubble-links-right {
  width: 50%;
  float: left;
  padding-bottom: 15px;
}

.bubble-container .bubble-body .bubble-links .bubble-links-right .bubble-links-right-img {
  width: 20px;
  height: 20px;
}

.bubble-container .bubble-body .bubble-links .bubble-links-right .bubble-links-right-dtls {
  font-size: 13px;
  font-family: 'open_sanssemibold', Arial, Helvetica, "sans serif";
  color: #3a84ef;
}

.bubble-container .bubble-triangle {
  width: 100%;
  padding-top: 3%;
  padding-left: 0px;
}

//END - borrowed styles from gema-ui for push pin marker infowindow


//START - new marker bubble styles
//TODO: fix me for responsiveness
.pii-bubble-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 0.5rem;
  margin-left: 7px; //to kinda balance with the infowindow right margin

  .header {

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.3rem;

      .address-icon {
        flex-basis: 5%;
        img {
          max-width: 24px;
          max-height: 24px;
        }
      }
      .address {
        flex-basis: 95%;
        font-size: 15px;
        font-weight: bold;
      }
  }

  .body {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      font-size: 13px;  //TODO add responsive font sizes

      .property-thumbnail {
          flex-basis: 30%;

          img {
            max-width: 130px;
            max-height: 100px;  //TODO add responsive image sizes
          }
      }

      .property-details {
          flex-basis: 70%;
          // height: 100%;

          display: flex;
          flex-direction: column;
          gap: 1rem;

          .description {
          }

          .pin-arn-container {
              display: flex;
              flex-direction: row;
              gap: 0.5rem;

              .pin-container {
                  flex-basis: 50%;

                  display: flex;
                  flex-direction: row;
                  justify-content: start;
                  gap: 0.3rem;

                  .pin-label {
                    font-weight: bold;;
                  }

                  .pin-value {

                  }
              }

              .arn-container {
                  flex-basis: 50%;

                  display: flex;
                  flex-direction: row;
                  justify-content: end;
                  gap: 0.3rem;

                  .arn-label {
                    font-weight: bold;
                  }

                  .arn-value {

                  }
              }

          }

          .links-container {
              display: flex;
              flex-direction: row;
              gap: 1rem;

              .link1-container {
                  flex-basis: 50%;

                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                  align-items: center;
                  gap: 0.3rem;

                  .icon {
                    max-width: 22px;
                    max-height: 22px;
                  }

                  .url {
                    font-size: 12px;
                    color: #3a84ef;
                    cursor: pointer;
                  }

                  .url-greyed {
                    font-size: 12px;
                    color: gray;
                  }
              }

              .link2-container {
                  flex-basis: 50%;

                  display: flex;
                  flex-direction: row;
                  justify-content: right;
                  align-items: center;
                  gap: 0.3rem;

                  .icon {
                    max-width: 22px;
                    max-height: 22px;
                  }

                  .url {

                    font-size: 12px;
                    color: #3a84ef;
                    cursor: pointer;
                  }
              }
          }

      }
  }
}
// END - new marker bubble styles
