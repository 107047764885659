@import 'responsive';
@import 'spacing';

$defaultFontFamily: Arial, Helvetica, "sans serif";
$regularFont: 'open_sansregular', $defaultFontFamily;
$boldFont: 'open_sansbold', $defaultFontFamily;
$semiboldFont: 'open_sanssemibold', $defaultFontFamily;

$sm-h1-font-size: 0.7rem;

$account-background-color: #e5ebf6;
$account-header-height: 80px;
$account-main-sub-component-height: calc(100vh - 226px);
$account-main-sub-component-height-xl: calc(100vh - 235.984px);