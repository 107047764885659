// START - omnibar styles
.ob-container {
  @apply flex flex-col md:flex-row align-middle gap-1
}
.ob-logo {
  @apply flex-[1_1_15%] h-full flex flex-row justify-start items-center pl-6
}
.ob-logo-img {
  @apply object-scale-down max-w-[220px] max-h-[50px] hidden md:block
}
.ob-search-type {
  /*@apply md:flex-[1_1_10%] flex flex-row justify-end*/
  /*@apply md:flex-[1_1_10%] flex-1 md:flex-initial flex flex-row justify-end*/
  @apply flex-1 md:flex-[1_1_10%] flex flex-row justify-end
}
.ob-search-type mat-form-field {
  @apply sm:w-[100%]
}
.ob-form {
  @apply  md:flex-[1_1_40%] flex flex-row justify-start items-center gap-1
}
.ob-form-autosuggest {
  @apply flex-1
}
.ob-form-autosuggest mat-form-field {
  width: 100%;
}
.ob-form-name-search {
  //@apply flex-1 flex flex-row justify-start items-center gap-1
  @apply lg:flex lg:mt-2 flex-1 gap-1
}
.ob-form-name-search mat-form-field {
  width: 100%;
}
.ob-form-concession {
  @apply w-full lg:w-1/4 xl:w-1/5 2xl:w-full #{!important};
}
.ob-instrument-plan-search {
  @apply flex-1 flex flex-row justify-start items-center gap-1
}
.ob-instrument-plan-search mat-form-field {
  width: 100%;
}
.ob-lotcon-search {
  @apply flex-1 flex flex-row justify-start items-center gap-1
}
.ob-lotcon-search mat-form-field {
  width: 100%;
}
.ob-lro {
  @apply flex-1 md:flex-[1_1_10%]
}
.ob-lro mat-form-field {
  @apply sm:w-[100%]
}
.ob-lro-note {
  @apply text-xs p-2;
}
.ob-lro-note-req {
  @apply text-xs p-2 anchor border-b border-solid border-b-gray-400;
}
.ob-lro-note-tooltip {
  display: block;
  max-width: 30vw;
}
.ob-search {
  @apply md:flex-[1_1_5%] self-center
}
.ob-search-comparables {
  @apply flex flex-col justify-center items-start w-full text-sm gap-1 ;
}
.ob-sc-toggle-container {
  @apply flex flex-row justify-center items-center gap-2 ;
}
.ob-search-comparables-label {
  @apply flex flex-row justify-center items-center gap-1 ;
}
.ob-search-comparables-counter {
  @apply flex flex-row justify-start items-center text-sm ;
}
.ob-search-comparables-renew {
  @apply flex flex-col md:flex-row justify-end items-center gap-2 ;
}
.ob-search-comparables-img {
  @apply min-w-[1.5rem] max-w-[1.5rem] ;
}
.ob-search-keyboard-shortcut {
  @apply rounded-lg bg-[#cfd0d1] border-gray-500 mx-1 p-1.5 ;

  .ob-search-keyboard-shortcut-key {
    @apply text-black ;
  }
}
.ob-search-comparables-dropdown-menu {
  button {
    @apply min-h-[36px] #{!important};

    span {
      @apply text-xs 2xl:text-sm #{!important};
    }
  }
}
// END - omnibar styles
