.ticker-tape-container {
  @apply mx-auto z-10 text-base top-2 static max-w-[100vw];
  .middle {
    #ticker-tape {
      @apply text-sm;

      .newsticker {
        @apply h-7 overflow-hidden border-solid border-[3px] border-[var(--color-dark-orange-secondary)] ;

        .nt-counter {
          @apply top-0 sm:-top-1;
        }

        .nt-title {
          @apply h-7;
          h2 {
            @apply h-7 leading-[1.4rem] text-sm font-semibold ;
          }
          span {
            @apply top-[.1rem] -right-2 ;
          }
        }

        i{ @apply top-2; }
        i.arrow-left {@apply left-[57px];}
        i.arrow-right { @apply right-[-29px]; }
      }

      .nt-content {
        @apply h-7 left-40 ;
        
        label {
          @apply h-7 leading-[1.35rem] text-[13px] font-semibold ;
        }

        a {
          text-decoration: underline !important;
          cursor: pointer;
          color: var(--color-dark-primary);
        }
      }
    }
  }
}
