.demographics-sub-header{
  @apply text-lg px-2 py-9 block
}

.demographics-header{
  @apply text-xl text-slate-800 px-1 py-0.5 bg-neutral-100 border-solid rounded-md border-slate-400  mt-[5%]
}

.demographics-values-na {
  font-size: 15px;
  line-height: 41px;
}

.demographics-data-header {
  padding: 12px 0 42px 0;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 26px;
  display:flex;
  justify-content: space-between;


  @media screen and (max-width: $desktopMax) {
    padding: 11px 0 40px 0;
    padding-left: 25px;
  }
  @media screen and (max-width: $desktopMin) {
    padding: 1% 0 2% 0;
    padding-left: 25px;
  }
  @media screen and (max-width: $tabletMax) {
    padding: 8px 0 30px 0;
    padding-left: 5px;
  }

  @media screen and (max-width: $mobileMax) {
    border-bottom: none;
  }

  li {
    font-size: 15px;
    margin: 0;
    float: left;
    margin-right: 10%;
    @media screen and (max-width: $desktopMax) {
      margin-right: 8%;
      font-size: 13px;
    }
    @media screen and (max-width: $desktopMin) {
      margin-right: 5%;
      font-size: 13px;
    }
    @media screen and (max-width: $tabletMax) {
      margin-right: 6%;
      font-size: 11px;
    }

    @media screen and (max-width: $mobileMax) {
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-right: 6%;
      font-size: 11px;
    }
    span {
      font-size: 30px;
      @media screen and (max-width: $desktopMax) {
        font-size: 24px;
      }
      @media screen and (max-width: $desktopMin) {
        font-size: 24px;
      }
      @media screen and (max-width: $tabletMax) {
        font-size: 20px;
      }
      @media screen and (max-width: $mobileMax) {
        font-size: 11px;
      }
    }
    .demographics-values-na {
      font-size: 15px;
      line-height: 41px;
      @media screen and (max-width: $desktopMax) {
        font-size: 13px;
        line-height: 32px;
      }
      @media screen and (max-width: $desktopMin) {
        font-size: 13px;
        line-height: 32px;
      }
      @media screen and (max-width: $tabletMax) {
        font-size: 11px;
        line-height: 20px;
      }
      @media screen and (max-width: $mobileMax) {
        font-size: 11px;
      }
    }
  }
  .title {
    margin-right: 10%;
    @media screen and (max-width: $desktopMax) {
      margin-right: 10%;
    }
    @media screen and (max-width: $desktopMin) {
      margin-right: 10%;
    }
    @media screen and (max-width: $tabletMax) {
      margin-right: 10%;
    }
    @media screen and (max-width: $mobileMax) {
      margin-right: 3%;
    }
    h5 {
      position: relative;
      top: 8px;
      @media screen and (max-width: $mobileMax) {
        top: 0px;
        font-weight: bold;
      }
      @media screen and (max-width: $tabletMax) {
        top: 0px;
        font-weight: bold;
      }
    }
  }
}

.demographics-circle {
  width: 13px;
  height: 13px;
  background: #000;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  @media screen and (max-width: $desktopMax) {
    width: 13px;
    height: 13px;
  }
  @media screen and (max-width: $desktopMin) {
    width: 13px;
    height: 13px;
  }
  @media screen and (max-width: $tabletMax) {
    width: 11px;
    height: 11px;
  }
}
.demographics-circle-color1 {
  background: #322d88;
}
.demographics-circle-color2 {
  background: #605ca8;
}
.demographics-circle-color3 {
  background: #afadd3;
}
.demographics-circle-color4 {
  background: #d7d6e9;
}
.demographics-circle-color5 {
  background: #10514e;
}
.demographics-circle-color6 {
  background: #0ca59e;
}
.demographics-circle-color7 {
  background: #85d2ce;
}
.demographics-circle-color8 {
  background: #c2e8e7;
}

.chart-doughnut-legend {
  float: left;
  display: inline-block;
  margin-top: 32px;
  margin-left: 40px;
  @media screen and (max-width: $desktopMax) {
    margin-top: 32px;
    margin-left: 40px;
  }
  @media screen and (max-width: $desktopMin) {
    margin-top: 32px;
    margin-left: 20px;
  }
  @media screen and (max-width: $tabletMax) {
    margin-top: 24px;
    margin-left: 17px;
  }

  td {
    font-family: $semiboldFont;
    //font-size: 13px;
    text-align: left;
    //padding: 0 0 10px 0;
    font-size: 11px;
    padding: 0 0 6px 0;
    padding-left: 0%;
    @media screen and (max-width: $desktopMax) {
      font-size: 11px;
      padding: 0 0 6px 0;
    }
    @media screen and (max-width: $desktopMin) {
      font-size: 11px;
      padding: 0 0 6px 0;
    }
    @media screen and (max-width: $tabletMax) {
      font-size: 10px;
      padding: 0 0 3px 0;
    }
  }
  .number {
    padding-left: 20%;
    font-family: $regularFont;
    text-align: right;
    @media screen and (max-width: $tabletMax) {
      padding-left: 10%;
    }
  }
  .number-education {
    padding-left: 42%;
    @media screen and (max-width: $tabletMax) {
      padding-left: 26%;
    }
  }
}
.demographics-right-border {
  @apply border-r border-r-gray-400;
}

p {
  @apply font-normal;
}